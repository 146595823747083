<template>
  <div>
    <List
      title="Customers"
      resource="customers"
      :basePath="basePath"
      :request-params="{deletedOnly: true}"
      :fields="fields"
      on-row-click="details"
      track-by="customerId"
      routeIdParam="customerId"
      :search="true"
      search-placeholder="Search for a customer by name"
    >
    </List>
  </div>
</template>

<script>
  import ModalNavigation from '@/mixins/ModalNavigation';
  import List from '@/components/auth/list/List';

  export default {
    name: "DeletedCustomersList",
    components: {
      List,
    },
    mixins: [ModalNavigation],
    data() {
      return {
        fields: [
          {
            name: 'name',
            title: 'Customer Name',
            sortField: 'name',
          },
          {
            name: 'propertiesCount',
            title: 'No. of Communities',
            sortField: 'propertiesCount',
            titleClass: 'text-center',
            dataClass: 'text-center',
          },
          {
            name: 'customerUsersCount',
            title: 'No. of Users',
            sortField: 'customerUsersCount',
            titleClass: 'text-center',
            dataClass: 'text-center',
          },
          {
            name: 'appsCount',
            title: 'No. of Apps',
            sortField: 'appsCount',
            titleClass: 'text-center',
            dataClass: 'text-center',
          },
        ]
      }
    },
    computed: {
      basePath: function () {
        return this.$route.path;
      },
    },
  }
</script>
